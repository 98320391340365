/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
@import "assets/demo/flags/flags.css";
@import '@angular/material/prebuilt-themes/indigo-pink.css';


html {
    height: 100%;
}

body {
    margin: 0;
    height: 100%;
    background-color: #ffffff !important;
    font-family: system-ui !important;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.p-tieredmenu {
    border: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

//Right-panel CSS
.sticky-panel {
    position: fixed;
    top: 300px;
    width: 250px;
    right: 30px;
    transition: top 0.3s ease-out;
}
.radius-0 {
    border-radius: 0px !important;
}
.radius-10 {
    border-radius: 10px !important;
}
.radius-3 {
    border-radius: 3px !important;
}
.p-center{
    display: flex;
    place-content: center;
}
.p-start{
    display: flex;
    place-content: start;
}
#side-panel .card{
    background: #F5F7F9;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    box-shadow: -3px 2px 4px rgba(0, 0, 0, 0.08);
}
::ng-deep #side-panel .p-dropdown{
    border:none;
}
::ng-deep #side-panel .p-dropdown:not(.p-disabled).p-focus{
    box-shadow: 0 0 0 0.2rem #6693d1 !important;
    border-color: #005CBA;
}
::ng-deep #side-panel .p-inputtext{
    border:none;
}
::ng-deep #side-panel .p-inputtext:not(.p-disabled).p-focus{
    box-shadow: 0 0 0 0.2rem #6693d1 !important;
    border-color: #005CBA;
}
.p-dropdown .p-dropdown-trigger{
    width: 2rem;
}
.p-toast {
    z-index: 999999999999 !important;
}

p-inputnumber {
    width: 100% !important;
}
.p-autocomplete.p-component::after {
    content: "\e908";
    font-family: 'primeicons';
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
    font-weight:900;
    position: absolute;
    right: 2px;
    top: 10px;
}

#login-form .mdc-text-field--outlined .mdc-notched-outline{
    opacity: 0 !important;
}

//Hazır Class
.dis-flex-center{
    display: flex;
    align-items: center;
    place-content: center
}
//Hazır Alan Classları
.flex {
    display: flex !important;
}
.a-w50{
    width: 50%;
    margin: auto;
}
.a-w60{
    display:flex;
    width: 60%;
    margin: auto;
}
.a-w65{
    display:flex;
    width: 65%;
    margin: auto;
}
.a-w70{
    display:flex;
    width: 70%;
    margin: auto;
}
.a-w80{
    display:flex;
    width: 80%;
    margin: auto;
}
.a-m10{
    margin: 10px;
}
.a-m20{
    margin: 20px;
}
.a-m30{
    margin: 30px;
}
.a-m40{
    margin: 40px;
}
.a-m50{
    margin: 50px;
}
.a-m60{
    margin: 60px;
}
.a-m70{
    margin: 70px;
}
.a-m80{
    margin: 80px;
}
.a-m90{
    margin: 90px;
}
.ali-center{
    align-items:center ;
}
.ali-right{
    align-items:right;
}
.ali-left{
    align-items:left;
}
.ali-justify{
    align-items:justify;
}
.as-center{
    align-self: center;
}
.as-auto{
    align-self:auto;
}
.border-none{
    border: none;
    border-width: 0;
}
.b-130{
    bottom: 130px;
}
.bp-center{
    background-position:center;
}
.bp-bottom{
    background-position:bottom;
}
.bp-left{
    background-position:left;
}
.bp-rigth{
    background-position:right;
}
.bg-white{
    background-color: #ffffff;
}
.c-pointer{
    cursor: pointer;
}
.color-white{
    color: white !important;
 }
.color-black{
    color: #414141 !important
}
.color-dark{
    color: #000;
}
.color-blue{
   color: #005CBA !important;
}
.color-site-blue{
    color: #1059c0;
}
.color-green{
   color: #04AE2A !important;
}
.color-write{
   color: #414141 !important;
}
.bg-white{
    background-color: white !important;
 }
.bg-black{
    background-color: #414141 !important
}
.bg-blue{
   background-color: #005CBA !important;
}
.bg-site-blue{
    background-color: #1059c0;
}
.bg-green{
   background-color: #04AE2A !important;
}
.bg-write{
   background-color: #414141 !important;
}
.center-align {
    text-align: center !important;
}
.start-align {
    text-align: start !important;
}
.end-align {
    text-align: end !important;
}
.d-none{
    display: none;
}
.d-block{
    display: block;
}
.d-contents{
    display: contents;
}
.d-flex{
    display: flex;
}
.d-inline{
    display: inline;
}
.d-inline-block{
    display: inline-block;
}
.d-inline-grid{
    display: inline-grid;
}
.f-black {
    color: #000 !important;
}
.d-grid{
    display: grid;
}
.fw-100{
    font-weight: 100;
}
.fw-200{
    font-weight: 200;
}
.fw-300{
    font-weight: 300;
}
.fw-400{
    font-weight: 400;
}
.fw-500{
    font-weight: 500;
}
.fw-600{
    font-weight: 600;
}
.fw-700{
    font-weight: 700;
}
.fw-bold{
    font-weight: bold;
}
.fsize-10 {
    font-size:10px !important;
}
.fsize-11 {
    font-size:11px !important;
}
.fsize-20{
    font-size: 20px;
}
.fsize-22{
    font-size: 22px;
}
.fsize-24{
    font-size: 24px;
}
.fsize-26{
    font-size: 26px;
}
.fsize-50{
    font-size: 50px;
}
.fsize-60{
    font-size: 60px;
}
.fsize-64{
    font-size: 64px;
}
.fsize-70{
    font-size: 70px;
}
.fsize-90{
    font-size: 90px;
}
.fstyle-normal{
    font-style: normal;
}
.fstyle-italic{
    font-style: italic;
}
.fstyle-inherit{
    font-style:inherit;
}
.ff-system-ui{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.h-35{
    height: 35px;
}
.h-45{
    height: 45px;
}
.h-50{
    height: 50px;
}
.h-53{
    height: 53px;
}
.h-60{
    height: 60px;
}
.h-77{
    height: 77px;
}
.h-100{
    height: 100px;
}
.h-150{
    height: 150px;
}
.h-200{
    height: 200px;
}
.h-250{
    height: 250px;
}
.h-300{
    height: 300px;
}
.h-350{
    height: 350px;
}
.h-400{
    height: 400px;
}
.h-percentage10{
    height: 10%;
}
.h-percentage20{
    height: 20%;
}
.h-percentage30{
    height: 30%;
}
.h-percentage40{
    height: 40%;
}
.h-percentage50{
    height: 50%;
}
.h-percentage60{
    height: 60%;
}
.h-percentage70{
    height: 70%;
}
.h-percentage80{
    height: 80%;
}
.h-percentage90{
    height: 90%;
}
.h-percentage100{
    height: 100%;
}
.jc-center{
    justify-content:center;
}
.jc-space-around{
    justify-content:space-around;
}
.jc-space-between{
    justify-content: space-between;
}
.jc-bottom{
    justify-content:bottom ;
}
.jc-left{
    justify-content:left;
}
.jc-right{
    justify-content:right;
}
.left-10{
    left: 10px;
}
.left-15{
    left: 15px;
}
.left-20{
    left: 20px;
}
.left-30{
    left: 30px;
}
.left-40{
    left: 40px;
}
.left-50{
    left: 50px;
}
.m-25{
    margin: 25px;
}
.m-auto{
    margin: auto;
}
.mb-10{
    margin-bottom: 10px;
}
.mb-15{
    margin-bottom: 15px;
}
.mb-20{
    margin-bottom: 20px;
}
.mb-30{
    margin-bottom: 30px;
}
.mb-40{
    margin-bottom: 40px;
}
.mb-50{
    margin-bottom: 50px;
}
.mb-60{
    margin-bottom: 60px;
}
.mb-70{
    margin-bottom: 70px;
}
.mb-80{
    margin-bottom: 80px;
}
.mb-90{
    margin-bottom: 90px;
}
.mb-100{
    margin-bottom: 100px;
}
.mb-200{
    margin-bottom: 200px;
}
.mb-300{
    margin-bottom: 300px;
}
.mb-400{
    margin-bottom: 400px;
}
.mb-500{
    margin-bottom: 500px;
}

.mbs-auto{
    margin-block-start: auto;
}
.mt-percentage3-1{
    margin-top: 3.1% !important;
}
.mt-2{
    margin-top: 2px;
}
.mt-5{
    margin-top: 5px;
}
.mt-10{
    margin-top: 10px;
}
.mt-13{
    margin-top: 13px !important;
}
.mt-20{
    margin-top: 20px;
}
.mt-30{
    margin-top: 30px;
}
.mt-40{
    margin-top: 40px;
}
.mt-50{
    margin-top: 50px;
}
.mt-60{
    margin-top: 60px;
}
.mt-70{
    margin-top: 70px;
}
.mt-80{
    margin-top: 80px;
}
.mt-90{
    margin-top: 90px;
}
.mr-5{
    margin-right: 5px;
}
.mr-10{
    margin-right: 10px;
}
.mr-15{
    margin-right: 15px;
}
.mr-20{
    margin-right: 20px;
}
.mr-30{
    margin-right: 30px;
}
.mr-40{
    margin-right: 40px;
}
.mr-50{
    margin-right: 50px;
}
.mr-60{
    margin-right: 60px;
}
.mr-70{
    margin-right: 70px;
}
.mr-80{
    margin-right: 80px;
}
.mr-90{
    margin-right: 90px;
}
.mr-170{
    margin-right: 170px;
}
.mr-255{
    margin-right: 255px;
}
.ml-5{
    margin-left: 5px;
}
.ml-10{
    margin-left: 10px;
}
.ml-20{
    margin-left: 20px;
}
.ml-25{
    margin-left: 25px;
}
.ml-30{
    margin-left: 30px;
}
.ml-40{
    margin-left: 40px;
}
.ml-50{
    margin-left: 50px !important;
}
.ml-60{
    margin-left: 60px;
}
.ml-70{
    margin-left: 70px;
}
.ml-80{
    margin-left: 80px;
}
.ml-90{
    margin-left: 90px;
}
.ml-230{
    margin-left: 230px;
}
.no-border {
    border: none !important
}
.no-padding {
    padding: 0px !important
}
.p-1{
    padding: 1px;
}
.p-5{
    padding: 5px;
}
.p-10{
    padding: 10px;
}
.p-20{
    padding: 20px;
}
.p-30{
    padding: 30px;
}
.p-40{
    padding: 40px;
}
.p-50{
    padding: 50px;
}

.p-static{
    position: static;
}
.p-absolute{
    position: absolute;
}
.p-inherit{
    position: inherit;
}
.p-fixed{
    position: fixed;
}
.p-relative{
    position: relative;
}
.p-sticky{
    position: sticky;
}


.pt-10{
    padding-top: 10px;
}
.pt-15{
    padding-top: 15px !important;
}
.pt-20{
    padding-top: 20px;
}
.pt-30{
    padding-top: 30px;
}
.pt-40{
    padding-top: 40px;
}
.pt-50{
    padding-top: 50px;
}
.pt-60{
    padding-top: 60px;
}
.pt-70{
    padding-top: 70px;
}
.pt-80{
    padding-top: 80px;
}
.pt-90{
    padding-top: 90px;
}

.pb-10{
    padding-bottom: 10px;
}
.pb-20{
    padding-bottom: 20px;
}
.pb-30{
    padding-bottom: 30px;
}
.pb-40{
    padding-bottom: 40px;
}
.pb-50{
    padding-bottom: 50px;
}
.pb-60{
    padding-bottom: 60px;
}
.pb-70{
    padding-bottom: 70px;
}
.pb-80{
    padding-bottom: 80px;
}
.pb-90{
    padding-bottom: 90px;
}

.pr-10{
    padding-right: 10px;
}
.pr-20{
    padding-right: 20px;
}
.pr-30{
    padding-right: 30px;
}
.pr-40{
    padding-right: 40px;
}
.pr-50{
    padding-right: 50px;
}
.pr-60{
    padding-right: 60px;
}
.pr-70{
    padding-right: 70px;
}
.pr-80{
    padding-right: 80px;
}
.pr-90{
    padding-right: 90px;
}

.pl-10{
    padding-left: 10px;
}
.pl-20{
    padding-left: 20px;
}
.pl-30{
    padding-left: 30px;
}
.pl-40{
    padding-left: 40px;
}
.pl-50{
    padding-left: 50px;
}
.pl-60{
    padding-left: 60px;
}
.pl-70{
    padding-left: 70px;
}
.pl-80{
    padding-left: 80px;
}
.pl-90{
    padding-left: 90px;
}
.ta-center {
    text-align: center;
}
.ta-start {
    text-align: start;
}
.ta-end{
    text-align: end;
}
.ta-left {
    text-align: left;
}
.ta-right {
    text-align: right;
}
.tal-center{
    text-align-last: center;
}
.tal-auto{
    text-align-last: auto;
}
.tal-justify{
    text-align-last: justify;
}
.tdl-none{
    text-decoration-line: none;
}
.tdl-overline{
    text-decoration-line: overline;
}
.tdl-underline{
    text-decoration-line: underline;
}
.td-underline{
    text-decoration: underline;
}
.w-45{
    width: 45px;
}
.w-50{
    width: 50px;
}
.w-60{
    width: 60px;
}
.w-70{
    width: 70px;
}
.w-80{
    width: 80px;
}
.w-90{
    width: 90px;
}
.w-100{
    width: 100px;
}
.w-110{
    width: 110px;
}
.w-120{
    width: 120px;
}
.w-150{
    width: 150px;
}
.w-170{
    width: 170px;
}
.w-200{
    width: 200px;
}
.w-230{
    width: 230px;
}
.w-245{
    width: 245px;
}
.w-246{
    width: 246px;
}
.w-250{
    width: 250px;
}
.w-300{
    width: 300px;
}
.w-330{
    width: 330px;
}
.w-350{
    width: 350px;
}
.w-400{
    width: 400px;
}
.w-450{
    width: 450px;
}
.w-585{
    width: 585px;
}
.w-percentage10{
    width: 10%;
}
.w-percentage20{
    width: 20%;
}
.w-percentage30{
    width: 30%;
}
.w-percentage40{
    width: 40%;
}
.w-percentage50{
    width: 50%;
}
.w-percentage60{
    width: 60%;
}
.w-percentage66{
    width: 66%;
}
.w-percentage70{
    width: 70%;
}
.w-percentage75{
    width: 75%;
}
.w-percentage80{
    width: 80%;
}
.w-percentage85{
    width: 85%;
}
.w-percentage90{
    width: 90%;
}
.w-percentage90{
    width: 95%;
}
.w-percentage100{
    width: 100%;
}
.ws-nowrap{
    white-space: nowrap;
}
.required {
    border-color: red !important;
}
.p-dropdown:has(.required) {
    border-color: red !important;
}
.right-panel-lbl {
    padding: 10px 10px 0px 10px;
}

.p-selectbutton .p-button.p-highlight {
    background: #000000;
    border-color: #ffffff;
    color: #FFFFFF;
}
.p-selectbutton .p-button.p-highlight:hover {
    background: #c9c9c9;
    border-color: #000000 !important;
    color: #000000 !important;
}
.p-dialog-content .sticky-panel {
    top: 55px !important
}

//login/register-page düzenlemesi
.language-select {
    color: #1059c0;
    margin: 25px;
    position: relative;
    display: inline-block;
    text-align-last: center;
    width: 325px;
  }
  .language-label {
    color: #1059c0;
    font-weight: bold;
    margin-right: 5px;
  }
  .selected-option {
    position: relative;
    display: inline-block;
    padding-right: 20px;
    cursor: pointer;
  }
  .arrow-icon {
    color: #1059c0;
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #000 transparent transparent transparent;
  }
  .arrow-up {
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent #000 transparent;
  }
  .options-container {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    background-color: #fff;
    z-index: 100;
  }
  .option {
    padding: 8px 10px;
    cursor: pointer;
  }
  .option.selected {
    background-color: #0066cc;
    color: #fff;
  }
  .option:hover {
    background-color: #e6e6e6;
  }
  .option.selected:hover {
    background-color: #0052a3;
  }
  .login-elements .custom-input input {
    flex-grow: 1;
    border: none;
    outline: none;
    height: 45px;
    font-size: 16px;
    background-color: #F6F6F6;
    width: 180px;
  }
  .register-elements1 .custom-input input{
    flex-grow: 1;
    border: none;
    outline: none;
    height: 45px;
    font-size: 14px;
    background-color: #F6F6F6;
    margin-bottom: 20px;
  }
  .custom-input {
    width: 50%;
    display: flex;
    align-items: center;
    position: relative;
}
.basic-line{
    border-top: 5px solid;
    border-radius: 3px;
    margin-top: 5px;
}
.uppercase{
    text-transform: uppercase;
}
.lowercase{
    text-transform: lowercase;
}
.menu-wrapper .layout-menu-container{
    height: 100%;
    justify-content: center !important;
    align-items: center;
  }
.mb-0-i{
    margin-bottom: 0 !important;
}
.p-toast .p-toast-message .p-toast-message-content{
    justify-content: space-between !important;
  }


div[data-id="zsalesiq"] {
    margin-bottom: 50px;
  }
.password-tricks.close {
    display: none;
}
.password-tricks {
    position: absolute;
    z-index: 2222;
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    top: -175px;
    right: 0px;
    border: 1px solid #ececec;
    opacity: 1;
}
.password-tricks::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #ececec;
    position: absolute;
    right: 0px;
    bottom: -10px;
}
.relative {
    position:  relative !important;
}

.upper {
    text-transform:uppercase !important;
}
.text-tonnage{
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* FONTS */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(assets/fonts/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
  }

  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }

  /* cyrillic-ext */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(assets/fonts/KFOlCnqEu92Fr1MmSU5fCRc4EsA.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(assets/fonts/KFOlCnqEu92Fr1MmSU5fABc4EsA.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(assets/fonts/KFOlCnqEu92Fr1MmSU5fCBc4EsA.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(assets/fonts/KFOlCnqEu92Fr1MmSU5fBxc4EsA.woff2) format('woff2');
    unicode-range: U+0370-03FF;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(assets/fonts/KFOlCnqEu92Fr1MmSU5fCxc4EsA.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(assets/fonts/KFOlCnqEu92Fr1MmSU5fChc4EsA.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(assets/fonts/KFOlCnqEu92Fr1MmSU5fBBc4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(assets/fonts/KFOmCnqEu92Fr1Mu72xKOzY.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(assets/fonts/KFOmCnqEu92Fr1Mu5mxKOzY.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(assets/fonts/KFOmCnqEu92Fr1Mu7mxKOzY.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(assets/fonts/KFOmCnqEu92Fr1Mu4WxKOzY.woff2) format('woff2');
    unicode-range: U+0370-03FF;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(assets/fonts/KFOmCnqEu92Fr1Mu7WxKOzY.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(assets/fonts/KFOmCnqEu92Fr1Mu7GxKOzY.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(assets/fonts/KFOmCnqEu92Fr1Mu4mxK.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(assets/fonts/KFOlCnqEu92Fr1MmEU9fCRc4EsA.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(assets/fonts/KFOlCnqEu92Fr1MmEU9fABc4EsA.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(assets/fonts/KFOlCnqEu92Fr1MmEU9fCBc4EsA.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(assets/fonts/KFOlCnqEu92Fr1MmEU9fBxc4EsA.woff2) format('woff2');
    unicode-range: U+0370-03FF;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(assets/fonts/KFOlCnqEu92Fr1MmEU9fCxc4EsA.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(assets/fonts/KFOlCnqEu92Fr1MmEU9fChc4EsA.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(assets/fonts/KFOlCnqEu92Fr1MmEU9fBBc4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  .pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  @-webkit-keyframes pulse {
  0% {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  }
  50% {
  -webkit-transform: scale3d(1.05, 1.05, 1.05);
  transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  }
  }
  @keyframes pulse {
  0% {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  }
  50% {
  -webkit-transform: scale3d(1.05, 1.05, 1.05);
  transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  }
  }
  #logo-link-mobile {
    display:none;
 }

  .wrap-txt {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }


    // general
    @media (max-width: 768px) { 

    .p-dialog .p-dialog-content {
        padding: 10px !important;
    }
    .stepper-main {
        margin-right: 0px !important;
        width: 100% !important;
    }
    x-rightpanel {
        display: none !important;
    }
    #zsiq_floatmain #siq_bL{
        display: none !important;
        opacity: 0;
    }
    .p-dialog{
        width: 100% !important;
    }
    ::ng-deep .p-dialog{
        width: 100% !important;
    }
    .weld-logo{
        height: 15px !important;
        margin-top: 12px !important;
        margin-left: 3px !important;
        position: relative !important;
        top: 5px !important;
    }
    .header-fixed-exit-btn > div {
        text-align: left !important;
    }
    .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .menu-button
    {
        display: none;
    }
    .layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-topbar-logo > img {
        margin-left: 0px !important;
        height: 50px !important;
    }
    .layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-rightpanel-button {
        margin-right: 0px !important;
    }
    app-footer {
        display: none !important;
    }

    #logo-link-mobile {
       display:block;
    }
    #logo-link {
        display:none;
     }
    }




// grid 
@media screen and (max-width: 768px) {
    .p-sm-1,
    .p-sm-2,
    .p-sm-3,
    .p-sm-4,
    .p-sm-5,
    .p-sm-6,
    .p-sm-7,
    .p-sm-8,
    .p-sm-9,
    .p-sm-10,
    .p-sm-11,
    .p-sm-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    }

    .p-sm-1 {
        width: 8.3333%;
    }

    .p-sm-2 {
        width: 16.6667%;
    }

    .p-sm-3 {
        width: 25%;
    }

    .p-sm-4 {
        width: 33.3333%;
    }

    .p-sm-5 {
        width: 41.6667%;
    }

    .p-sm-6 {
        width: 50%;
    }

    .p-sm-7 {
        width: 58.3333%;
    }

    .p-sm-8 {
        width: 66.6667%;
    }

    .p-sm-9 {
        width: 75%;
    }

    .p-sm-10 {
        width: 83.3333%;
    }

    .p-sm-11 {
        width: 91.6667%;
    }

    .p-sm-12 {
        width: 100%;
    }

    .p-sm-offset-12 {
        margin-left: 100%;
    }

    .p-sm-offset-11 {
        margin-left: 91.66666667%;
    }

    .p-sm-offset-10 {
        margin-left: 83.33333333%;
    }

    .p-sm-offset-9 {
        margin-left: 75%;
    }

    .p-sm-offset-8 {
        margin-left: 66.66666667%;
    }

    .p-sm-offset-7 {
        margin-left: 58.33333333%;
    }

    .p-sm-offset-6 {
        margin-left: 50%;
    }

    .p-sm-offset-5 {
        margin-left: 41.66666667%;
    }

    .p-sm-offset-4 {
        margin-left: 33.33333333%;
    }

    .p-sm-offset-3 {
        margin-left: 25%;
    }

    .p-sm-offset-2 {
        margin-left: 16.66666667%;
    }

    .p-sm-offset-1 {
        margin-left: 8.33333333%;
    }

    .p-sm-offset-0 {
        margin-left: 0%;
    }

    .p-sm-1, .p-sm-2, .p-sm-3, .p-sm-4, .p-sm-5, .p-sm-6, .p-sm-7, .p-sm-8, .p-sm-9, .p-sm-10, .p-sm-11, .p-sm-12, .p-md-1, .p-md-2, .p-md-3, .p-md-4, .p-md-5, .p-md-6, .p-md-7, .p-md-8, .p-md-9, .p-md-10, .p-md-11, .p-md-12, .p-lg-1, .p-lg-2, .p-lg-3, .p-lg-4, .p-lg-5, .p-lg-6, .p-lg-7, .p-lg-8, .p-lg-9, .p-lg-10, .p-lg-11, .p-lg-12, .p-xl-1, .p-xl-2, .p-xl-3, .p-xl-4, .p-xl-5, .p-xl-6, .p-xl-7, .p-xl-8, .p-xl-9, .p-xl-10, .p-xl-11, .p-xl-12 {
        padding: 0.3rem !important;
    }
}
